import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import Stack from "@mui/material/Stack";

const Title = ({ text, ...otherProps }) => {
  return (
    <Stack direction="row" alignItems="center" gap={3} mt={10} {...otherProps}>
      <IconButton
        sx={{ "& .MuiSvgIcon-root": { paddingRight: "5px" } }}
        component={RouterLink}
        aria-label="back-button"
        color="primary"
        size="large"
        to="/"
      >
        <ArrowBackIcon fontSize="inherit" />
      </IconButton>
      <Typography variant="h3" fontWeight="bold">
        {text}
      </Typography>
    </Stack>
  );
};

export default Title;
