import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useLocation } from "react-router-dom";

import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";
import ImageSlider from "../ImageSlider";

const ProductPage = () => {
  const location = useLocation();
  const PHTFragment = () => (
    <>
      <Typography
        id="pht-infrastructure"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        PHT Infrastructure
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <img
            width="100%"
            src="./assets/product/pht.jpeg"
            style={{ borderRadius: 20, marginBottom: 10 }}
            alt="PHT Infrastructure"
          />
          <Typography component="span" fontWeight="bold" mr={1}>
            Overview of PHT infrastructure:
          </Typography>
          <Typography component="span">
            This architecture consists of a central managing unit and a
            separated autonomous Station environment. Each sub-component is
            accessible via a browser. All in all, we have four communication
            channels between the Central Service component and Station(s):{" "}
            <strong>Pull, Push, Queue Request, and Reject</strong>. Based on
            these commands, our <em>Train Configurator</em> manages the
            synchronisation of the <em>Train Registry</em> and the{" "}
            <em>Central Service</em> database.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h4" fontWeight="bold" my={5}>
        Overview
      </Typography>
      <Typography variant="h6" mb={3}>
        The PHT originates from an analogy from the real world. The
        infrastructure reminds of a railway system including trains, stations,
        and train depots. The train uses the network to visit different stations
        to transport, e.g., several goods.
      </Typography>
      <Typography variant="h6" mb={3}>
        Adapting this concept to the PHT ecosystem, we can draw the following
        similarities. The{" "}
        <Link href="#train-creation-wizard" underline="hover" color="blue">
          Train
        </Link>{" "}
        encapsulates an analytical task, which is represented by the good in the
        analogy. The data provider takes over the role of a reachable{" "}
        <Link href="#station-software" underline="hover" color="blue">
          Station
        </Link>
        , which can be accessed by the Train. Further, the Station executes the
        task, which processes the available data. The depot is represented by
        our{" "}
        <Link href="#central-service" underline="hover" color="blue">
          Central Service (CS)
        </Link>{" "}
        including procedures for Train orchestration, operational logic,
        business logic, and data management. The goal of this design paradigm is
        to bring the algorithm to the data instead of bringing confidential data
        to the algorithm, which enables the compliance to data protection
        requirements.
      </Typography>
      <Typography variant="h6" mb={3}>
        Furthermore, we pay attention to the following design aspects. Every
        component of our architecture is containerised using the{" "}
        <Link href="http://docker.com/" underline="hover" color="blue">
          Docker
        </Link>{" "}
        technology to facilitate software development. In addition, the
        components are loosely coupled to enable possible extensions and (REST
        API) web service orchestration. For improved usability, each node is
        accessible via a browser.
      </Typography>
    </>
  );

  const CentralServiceFragment = () => (
    <>
      <Typography
        id="central-service"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        Central Service (CS)
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={8}>
          <img
            width="100%"
            src="./assets/product/centralService.jpg"
            style={{ borderRadius: 20, marginBottom: 10 }}
            alt="Central Service"
          />
          <Typography component="span" fontWeight="bold" mr={1}>
            Overview of the Central Service Components:
          </Typography>
          <Typography component="span">
            Our Central Service is a dockerised Web application providing
            interfaces for Monitoring of the algorithms (orange) and components,
            which manage the business logic of our architecture.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h4" fontWeight="bold" my={5}>
        Overview
      </Typography>
      <Typography variant="h6" mb={3}>
        The CS provides several monitoring and management services, e.g., to
        define and execute the Station sequence, to secure intermediate and
        final results generated by the Trains, and to provide interfaces to the
        repositories of each operating partner in our architecture.
      </Typography>
      <Typography variant="h6" mb={3}>
        In the figure above, we depicted a general workflow of our CS. First, it
        covers a so-called <b>Train Class Repository</b>. Researchers and
        scientists are able to propose and store their developed analytic
        algorithms containerised in a base image, which is termed{" "}
        <b>Train Class</b>, and make them available to others (see{" "}
        <Link
          href="#train-creation-wizard"
          underline="hover"
          color="blue"
          fontWeight="bold"
        >
          Train Creation Wizard
        </Link>
        ). If the researcher requests an analytical job and defines a sequence
        of Stations to be visited, the CS replicates the base image and stores
        it in the repository of the first positioned Station in the sequence.
        After the Station pulled, executed, and pushed the Train back, the CS
        copies the new Train image into two repositories: The User Repository
        representing the execution history and the repository of the next
        Station in the sequence for the subsequent Train execution. The CS is
        developed as a REST web service running on an Apache Web Server. This
        service utilise APIs and Webhooks to trigger the above-mentioned
        procedures, e.g., <em>Train pulled, Train pushed</em> or
        <em>Train rejected</em>.
      </Typography>
      <Typography variant="h4" fontWeight="bold" mt={5} mb={8}>
        Components
      </Typography>
      <Grid container alignItems="baseline" spacing={5}>
        <Grid item container sm={6}>
          <Grid item xs={12} textAlign="center">
            <img src="./assets/product/harbor.png" width="60%" alt="Harbor" />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              As central Train Repository, we use{" "}
              <Link href="http://goharbor.io/" underline="hover" color="blue">
                Harbor
              </Link>{" "}
              to store the Train images and to provide role-based and
              access-controlled (RBAC) repositories.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid item xs={12} textAlign="center">
            <img
              src="./assets/product/keycloak.png"
              width="60%"
              alt="Keycloak"
            />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              The access for scientists and researchers is controlled by an
              identity and access management (IAM) component on the central
              unit. As IAM, we use the open-source software{" "}
              <Link href="http://keycloak.org/" underline="hover" color="blue">
                Keycloak
              </Link>{" "}
              to manage user accounts and access authorisations for components
              of our central services.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid item xs={12} textAlign="center">
            <img
              src="./assets/product/blazegraph.png"
              width="60%"
              alt="Blazegraph"
            />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              We use a{" "}
              <Link
                href="https://blazegraph.com/"
                underline="hover"
                color="blue"
              >
                BlazeGraph
              </Link>{" "}
              server to store information about transactions and information
              emitted by the Train itself. The information is formatted
              according to Semantic Web standards and our developed{" "}
              <Link
                href="https://github.com/LaurenzNeumann/PHTMetadata"
                underline="hover"
                color="blue"
              >
                Metadata Schema
              </Link>{" "}
              for our components. The BlazeGraph database enables transparency
              and poses an opportunity for further optimisations.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid item xs={12} textAlign="center">
            <img src="./assets/product/grafana.svg" width="60%" alt="Grafana" />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              We use{" "}
              <Link href="https://grafana.com/" underline="hover" color="blue">
                Grafana
              </Link>
              , an open source visual analytics platform, for the creation of
              user-friendly and customisable monitoring dashboards by{" "}
              <Link href="https://grafana.com/" underline="hover" color="blue">
                GrafanaLabs
              </Link>
              . It supports various data sources for the visualisation of
              important information about the user's algorithm or in-depth
              information about the Station(s).
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const StationSoftwareFragment = () => (
    <>
      <Typography
        id="station-software"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        Station Software
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={10}>
          <img
            width="100%"
            src="./assets/product/stationSoftware.jpeg"
            style={{ borderRadius: 20, marginBottom: 10 }}
            alt="Station Software"
          />
          <Typography component="span" fontWeight="bold" mr={1}>
            Overview of the Station Components:
          </Typography>
          <Typography component="span">
            Our Station Software is a <em>dockerised</em>&nbsp;&nbsp;Web
            application providing interfaces for Monitoring of the algorithms
            (orange) and components, which manage the business logic, e.g.,
            pulling a train, execute a train, inspect the results, and many
            more.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h4" fontWeight="bold" my={5}>
        Overview
      </Typography>
      <Typography variant="h6" mb={3}>
        Stations are the nodes in the distributed architecture that hold the
        confidential data and execute analytic tasks. Each <b>Station</b> is
        registered in a
        <Link
          href="#station-registry"
          underline="hover"
          fontWeight="bold"
          color="blue"
        >
          {" "}
          Station Registry{" "}
        </Link>
        and acts as an autonomous and independent unit. In the distributed
        architecture, the communication is designed to be one-way, i.e.,
        Stations are actively polling the CS if there are Train requests waiting
        to be executed. In contrast, the CS does not have an active channel to
        the Station such that the Station admins or curators of
        privacy-sensitive data have at any time the high-level sovereignty of
        any activities affecting the corresponding Station.{" "}
        <b>Our four communication commands are:</b>
      </Typography>
      <ul>
        <Typography component="li" variant="h6">
          Poll for new Trains
        </Typography>
        <Typography component="li" variant="h6">
          Pull a Train from the repository
        </Typography>
        <Typography component="li" variant="h6">
          Reject a Train from the repository
        </Typography>
        <Typography component="li" variant="h6">
          Push a Train back to the repository
        </Typography>
      </ul>
      <Typography id="station-registry" variant="h4" fontWeight="bold" my={5}>
        Station Registry
      </Typography>
      <Typography variant="h6" mb={3}>
        The station registry is an application which supports the PHT
        infrastructure. It is used to onboard stations and to give an overview
        of the registered stations and their corresponding components.
        Information related to a station is divided into the organization where
        it belongs to, the station type, the onboarding status and the project
        it is used for. A proper managed station registry is necessary for the
        successful execution of distributed analyses in the PHT project.
      </Typography>
      <Typography variant="h6" my={3}>
        A workflow, which explains the activities in more detail, is given in
        the following picture.
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={12}>
          <img
            width="100%"
            src="./assets/product/swimline.png"
            style={{ marginBottom: 10 }}
            alt="Swimlane Diagram for a Train request"
          />
          <Typography component="span" fontWeight="bold" mr={1}>
            Swimlane Diagram for a Train request:
          </Typography>
          <Typography component="span">
            Three instances are involved during a Train request and execution.
            The scientist requests the Train and the CS manages the whole
            communication with the Station. The Station has the opportunity to
            reject the Train before each pull/push operation to avoid malicious
            activities or to prohibit the return of sensitive results.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" my={3}>
        The Station has two main components: The data source and the Station
        Software. The Station can hold the data itself or provides an access
        point to the sensitive data. The main task of the Stations is the
        execution of the <em>containerised</em> analytic algorithms (
        <Link
          href="#train-creation-wizard"
          underline="hover"
          color="blue"
          fontWeight="bold"
        >
          Trains
        </Link>
        ). Therefore, every Station communicates with a local{" "}
        <Link href="https://www.docker.com/" underline="hover" color="blue">
          Docker engine
        </Link>{" "}
        to execute a Train. This execution consists of five steps, i.e., pulling
        image from Train Registry to the local machine, creating and starting
        the container of the corresponding pulled Train image, committing the
        container to create a new image from the container's changes, and
        pushing the changed image back to the Train Repository. Furthermore,
        since the data providers or institutions could have different
        authentication procedures, we do not restrict the Station software to
        one single authentication technology and leave the integration of the
        authentication mechanism to the institution. In addition, each Station
        provides a graphical user interface representing a management console to
        coordinate the Train execution cycle (For more information have a look
        into our{" "}
        <Link
          href="#train-creation-wizard"
          underline="hover"
          color="blue"
          fontWeight="bold"
        >
          Train Creation Wizard
        </Link>
        ).
      </Typography>
      <Typography variant="h4" fontWeight="bold" mt={5} mb={8}>
        Components
      </Typography>
      <Grid container alignItems="baseline" spacing={5}>
        <Grid item container sm={4}>
          <Grid item xs={12} textAlign="center">
            <img src="./assets/product/docker.png" width="75%" alt="Docker" />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              Our Trains are executed in a{" "}
              <Link
                href="https://hub.docker.com/_/docker"
                underline="hover"
                color="blue"
              >
                Docker in Docker Container
              </Link>
              , which runs on the Host Docker Engine. This has the advantage
              that the Trains have their own sandbox and do not interfere with
              the containers on the Host engine.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container sm={4}>
          <Grid item xs={12} textAlign="center">
            <img src="./assets/product/mongodb.png" width="75%" alt="MongoDB" />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              We use a{" "}
              <Link
                href="https://www.mongodb.com/"
                underline="hover"
                color="blue"
              >
                mongoDB
              </Link>{" "}
              container to store transaction logs, which can be reused for
              debugging purposes.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container sm={4}>
          <Grid item xs={12} textAlign="center">
            <img src="./assets/product/vault.png" width="75%" alt="Vault" />
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography variant="h6">
              In order to store Station-related and sensitive data/credentials,
              we use a{" "}
              <Link
                href="https://www.vaultproject.io/"
                underline="hover"
                color="blue"
              >
                Vault
              </Link>{" "}
              container. Vault can easily be configured by our Station GUI and
              facilitates the Train execution process via an easy-to-use
              interface.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const TrainCreationFragment = () => (
    <>
      <Typography
        id="train-creation-wizard"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        Train & Creation Wizard
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={10}>
          <img
            width="100%"
            src="./assets/product/trainCreation.png"
            style={{ borderRadius: 20, marginBottom: 10 }}
            alt="Train Creation"
          />
          <Typography component="span" fontWeight="bold" mr={1}>
            Train Lifecycle Diagram:
          </Typography>
          <Typography component="span">
            In general, we have two state types in our workflow. The first
            (yellow) states represent the states of the Train Class in the App
            Store. If a researcher requests a Train, the Train is instantiated
            and is following the states in the lower part of the figure. The
            lower part represents the actual Train circulation in our Station
            network.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h4" fontWeight="bold" my={5}>
        Overview
      </Typography>
      <Typography variant="h6" mb={3}>
        In general, <b>Trains</b> contain specific analysis tasks, which are
        executed at distributed data nodes - the{" "}
        <Link
          href="#station-software"
          underline="hover"
          color="blue"
          fontWeight="bold"
        >
          Stations
        </Link>
        . In order to complete their task, a Train moves from Station to Station
        to consume data as an outcome of the executed analytical task. The Train
        requester, e.g., a researcher selects the Station sequence to be
        visited. The results are incrementally generated and can be anything
        based on the Train code. For example, the result set can contain data on
        an aggregated level, e.g., number showing a cohort size, which has no
        relationship to individual patient data of the input level, or updated
        parameters of a statistical model, such as a regression model that is
        incrementally fitted from Station to Station.
      </Typography>
      <Typography variant="h6" mb={3}>
        Trains can have several states in their lifecycle, which adds
        transparency about the state-flow of a Train itself and provides
        information about the Train status to the users. Possible states are
        illustrated in Figure above.
      </Typography>
      <Typography variant="h6" mb={3}>
        First, a Train Class is created and stored in a so-called App Store
        (Train Class Repository) after the domain community examined the Train
        to prohibit malicious code executions at the Stations. If a researcher
        wants to conduct a data study, the researcher selects a suitable Train
        Class and a new Train Instance is created. According to our workflow
        definition, a Train can be in an idle state if it waits in the queue for
        being pulled by a Station. After the transmission, the Train is in the
        running state if the Station executes it. If the Train execution at a
        Station was successful, the Train is pushed back to the repository and
        the workflow starts again according to the selected Station sequence. In
        unsuccessful cases, the Train is also pushed back but for debugging
        purposes and the sequence stops.
      </Typography>
      <Typography variant="h6" mb={3}>
        Further, the statechart above includes corresponding states if the
        Station sequence was processed successfully or an error occurred.
        Finally, the Train requester is able to inspect the results. The
        advantage is that at any point in time, the Train requester is aware of
        the current state, which is a leverage for the usability of such a
        network.
      </Typography>
      <Typography variant="h4" fontWeight="bold" mt={5} mb={2}>
        Train Creation Wizard
      </Typography>
      <Typography variant="h6">Will be released soon!</Typography>
    </>
  );

  const GalleryFragment = () => (
    <>
      <Typography id="gallery" variant="h4" fontWeight="bold" mt={5} mb={2}>
        Gallery
      </Typography>
      <Typography variant="h6">
        Explore some screenshots of our infrstructure!
      </Typography>
      <ImageSlider />
    </>
  );

  return (
    <Container>
      {!location.hash ? <ScrollToTop /> : null}
      <Title text="Welcome to PADME" mb={5} />
      <Typography variant="h6" mb={3}>
        PADME is a Distributed Analytics (DA) infrastructure that brings the
        algorithms to the data instead of vice versa. By following this paradigm
        shift, it proposes a solution for persistent privacy-related challenges.
        It is developed in compliance with{" "}
        <Link
          href="https://www.go-fair.org/implementation-networks/overview/personal-health-train/"
          underline="hover"
          target="_blank"
          color="blue"
        >
          Personal Health Train(PHT)
        </Link>{" "}
        approach. It provides a generic solution not limited to the health
        domain but any domain that need to analyze distributed data.
      </Typography>
      <Typography variant="h6" mb={3}>
        PHT is a novel approach, aiming to establish a distributed data
        analytics infrastructure enabling the (re)use of distributed healthcare
        data, while data owners stay in control of their data. The main
        principle of the PHT is that data remains in its original location, and
        analytical tasks visit data sources and execute the tasks. The PHT
        provides a distributed, flexible approach to use data in a network of
        participants, incorporating the FAIR principles.
      </Typography>
      <Typography variant="h6">
        Our study is part of German MII and{" "}
        <Link
          href="https://www.go-fair.org/go-fair-initiative/"
          underline="hover"
          target="_blank"
          color="blue"
        >
          GoFAIR
        </Link>{" "}
        initiatives.
      </Typography>
      <PHTFragment />
      <CentralServiceFragment />
      <StationSoftwareFragment />
      <TrainCreationFragment />
      <GalleryFragment />
    </Container>
  );
};

export default ProductPage;
