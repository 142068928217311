import { BrowserRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./components/LandingPage";
import ProjectsPage from "./components/ProjectsPage";
import BlogsPage from "./components/BlogsPage";
import Footer from "./components/layout/Footer";
import ProductPage from "./components/ProductPage";
import PublicationsPage from "./components/PublicationsPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/blog-and-news" element={<BlogsPage />} />
        <Route path="/product-overview" element={<ProductPage />} />
        <Route path="/publications" element={<PublicationsPage />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem", textAlign: "center" }}>
              <p>(404) Page Not Found</p>
            </main>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
