import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  slide: {
    opacity: 0,
    transitionDuration: "1s ease",
  },
  active: {
    opacity: 1,
    transitionDuration: "1s",
    transform: "scale(1)",
  },
  imageContent: {
    width: 1000,
    height: 600,
    borderRadius: 20,
    objectFit: "contain",
  },
}));

const sliderData = [
  { image: "./assets/product/gallery-1.png" },
  { image: "./assets/product/gallery-2.png" },
  { image: "./assets/product/gallery-3.png" },
  { image: "./assets/product/gallery-4.png" },
  { image: "./assets/product/gallery-5.png" },
  { image: "./assets/product/gallery-6.png" },
  { image: "./assets/product/gallery-7.png" },
  { image: "./assets/product/gallery-8.png" },
  { image: "./assets/product/gallery-9.png" },
  { image: "./assets/product/gallery-10.png" },
];

const ImageSlider = () => {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const length = sliderData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <IconButton
          className="left-arrow"
          aria-label="left-arrow"
          onClick={prevSlide}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item>
        {sliderData.map((slide, ind) => (
          <div
            className={ind === current ? classes.active : classes.slide}
            key={ind}
          >
            {ind === current && (
              <img
                src={slide.image}
                alt={slide.image}
                className={classes.imageContent}
              />
            )}
          </div>
        ))}
      </Grid>
      <Grid item>
        <IconButton
          className="right-arrow"
          aria-label="right-arrow"
          onClick={nextSlide}
        >
          <ChevronRightIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ImageSlider;
