import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(10),
  },
  mainContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(10),
    position: "relative",
  },
  ribbon: {
    position: "absolute",
    top: -10,
    left: -10,
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::before": {
      content: '"A Personal Health Train Implementation"',
      position: "absolute",
      width: "300%",
      height: "40%",
      background: "#e65100",
      transform: "rotate(-45deg) translateY(100px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "uppercase",
      fontWeight: "bold",
      color: "white"
    },
  },
  mainTitle: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      textAlign: "center",
    },
    "& .MuiTypography-root": {
      color: "white",
      letterSpacing: 1.5,
    },
  },
  featurePaper: {
    margin: "0 24px",
    marginTop: "-60px",
    padding: 16,
    paddingBottom: theme.spacing(10),
    borderRadius: 20,
    boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
    position: "relative",
  },
  card: {
    width: 345,
    borderRadius: 20,
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem",
    "& .MuiCardActions-root": {
      padding: "0 2.5em 2.5em",
      position: "absolute",
      bottom: 0,
    },
    "& .btn-feature": {
      borderRadius: 20,
    },
  },
  cContent: {
    padding: "2.5em",
    fontFamily: "Manrope",
    "& .feature-desc": {
      marginBottom: 60,
    },
  },
  blogImg: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "30%",
  },
  actionBtn: {
    position: "fixed",
    outline: "none",
    bottom: theme.spacing(5),
    right: theme.spacing(2),
  },
  moreBtn: {
    borderRadius: 50,
    borderColor: theme.palette.primary.main,
    marginTop: "3em",
    fontSize: "20px",
    fontWeight: "bold",
  },
  videoContainer: {
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
    position: "relative",
    "& iframe": {
      borderRadius: 20,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  },
}));
